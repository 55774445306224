import React from 'react'
import './spinner.css';
const Spinner = (props) => {
  return (
    <div className='loading'>
      <div className='loader'></div>
    </div>

  )
}

export default Spinner;
