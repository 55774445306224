import React from 'react';
//import moment from 'moment';
import {FacebookShareButton, FacebookIcon,TwitterShareButton,TwitterIcon,LinkedinShareButton,LinkedinIcon} from "react-share";

const Blog = ({ provider,source, title, description, url, urlToImage, publishedAt,image,datePublished }) =>

    <blog>
        <a href={url} className='article' target="_blank" rel="noopener noreferrer">
            <img alt={title} className='article-image' src={image.url} />
            {/* <div className='article__content' style={{backgroundImage: 'url(' +image.url+ ')',backgroundColor:'#E8584F',backgroundBlendMode:'hard-light'}}> */}
            <div className='article__content'>
                <h3 className='article-headline'>{title}</h3>
                <p className='article-description p-t-1 p-b-1'>{description}</p>
                <div className='article__details'>
                    <div>
                    <h4 className='article-source'>{provider.name}</h4>
                    <h4 className='article-date'>{new Date(datePublished).toLocaleString()}</h4>
                    </div>
                    <div>
                    <FacebookShareButton 
                        url={url}
                        quote={title}
                        hashtag="#MUFC"
                        style={{margin:'2px'}}>
                        <FacebookIcon size={24} style={{borderRadius:'5px'}}/>
                    </FacebookShareButton>
                    <TwitterShareButton 
                        url={url}
                        quote={title}
                        hashtag="#MUFC"
                        style={{margin:'2px'}}>
                        <TwitterIcon size={24} style={{borderRadius:'5px'}}/>
                    </TwitterShareButton>
                    <LinkedinShareButton 
                        url={url}
                        quote={title}
                        hashtag="#MUFC"
                        style={{margin:'2px'}}>
                        <LinkedinIcon size={24} style={{borderRadius:'5px'}}/>
                    </LinkedinShareButton>
                    </div>
                </div>
            </div>
        </a>
    </blog>

export default Blog;