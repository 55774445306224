import React from 'react';
import moment from 'moment';
import {FacebookShareButton, FacebookIcon,TwitterShareButton,TwitterIcon,LinkedinShareButton,LinkedinIcon} from "react-share";
import SpotifyPlayer from 'react-spotify-player';

// size may also be a plain string using the presets 'large' or 'compact'
const size = {
  width: '100%',
  height: '70%',
};
const view = 'coverart'; // or 'coverart'
const theme = 'black'; // or 'white'

const Podcast = ({ name, description, uri, images, release_date, external_urls }) =>

    <podcast>
        <div className='podcast'>
            
            {/* <img alt={name} className='article-image' src={images[0].url} /> */}
            {/* <div className='article__content' style={{backgroundImage: 'url(' +image.url+ ')',backgroundColor:'#E8584F',backgroundBlendMode:'hard-light'}}> */}
            <div className='podcast__content'>
                <SpotifyPlayer
                    uri={uri}
                    size={size}
                    view={view}
                    theme={theme}
                />
                <h3 className='podcast-headline'>{name}</h3>
                <p className='podcast-description p-t-1 p-b-1'>{description}</p>
                <div className='podcast__details'>
                    <div>
                    <h4 className='podcast-date'>{new moment(release_date).fromNow()}</h4>
                    </div>
                    <div>
                    <FacebookShareButton 
                        url={external_urls.spotify}
                        quote={name}
                        hashtag="#MUFC"
                        style={{margin:'2px'}}>
                        <FacebookIcon size={24} style={{borderRadius:'5px'}}/>
                    </FacebookShareButton>
                    <TwitterShareButton 
                        url={external_urls.spotify}
                        quote={name}
                        hashtag="#MUFC"
                        style={{margin:'2px'}}>
                        <TwitterIcon size={24} style={{borderRadius:'5px'}}/>
                    </TwitterShareButton>
                    <LinkedinShareButton 
                        url={external_urls.spotify}
                        quote={name}
                        hashtag="#MUFC"
                        style={{margin:'2px'}}>
                        <LinkedinIcon size={24} style={{borderRadius:'5px'}}/>
                    </LinkedinShareButton>
                    </div>
                    
                </div>
            </div>
        </div>
    </podcast>

export default Podcast;