import React from 'react'
import Spinner from '../components/Spinner';
import Podcast from '../components/Podcast';
import axios from 'axios';
import ArticleError from '../components/ArticleError';


class Podcasts extends React.Component {

    signal = axios.CancelToken.source();

    state = {
        articles: [],
        loading: true,
        error: false
    }

    fetchArticles() {
    
        fetch("https://ai-articles.s3.ap-south-1.amazonaws.com/ManUtdPodcasts.json")
                .then(response => response.json())
                    .then(data => {
                        data.episodes.items = data.episodes.items.filter(function (el) {
                            return el != null;
                          });
                        data.episodes.items.sort((a, b) => new Date(b.release_date).getTime() - new Date(a.release_date).getTime());
                        // console.log(data)
                        this.setState({
                        articles: data.episodes.items.slice(0,100),
                        loading: false
                        })
                    })
                    .catch(err => {
                        console.log(err)
                        console.error(err);
                        this.setState({
                            error: true,
                            loading: false
                        })
                    });
    }

    componentDidMount() {
        this.fetchArticles();
    }

    componentUnmount() {
        this.signal.cancel('Leaving page')
    }

    renderArticles = articles => articles.map((article, index) => <Podcast key={index} {...article} />)

    render() {
        if (this.state.error) return <ArticleError />
        if (this.state.loading) return <Spinner />
        return (
            <div className='container-fluid'>
                {/* <p className='banner'>Showing {this.state.articles.length} results for: Top Headlines</p> */}
                <ul className='podcast-container'>
                    {this.renderArticles(this.state.articles)}
                </ul>
            </div>
        )
    }

}

export default Podcasts